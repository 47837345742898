import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { geocoder } from '@zupr/utils/geolocation'

import DomainContext from './domain'

interface Cardinals {
    n: number
    s: number
    e: number
    w: number
}

interface GeolocationContextProvider {
    center: google.maps.LatLngLiteral
    isGmLoaded: boolean
    gBounds: google.maps.LatLngBounds
    zoom: number
    setBounds: ({
        gBounds,
        center,
        zoom,
    }: {
        gBounds: google.maps.LatLngBounds
        center: google.maps.LatLngLiteral
        zoom: number
    }) => void
    setGmLoaded: React.Dispatch<React.SetStateAction<boolean>>
    viewport: google.maps.places.PlaceGeometry['viewport']
    setViewport: (
        viewport: google.maps.places.PlaceGeometry['viewport']
    ) => void
    cardinals: Cardinals
}

const GeolocationContext = React.createContext<GeolocationContextProvider>(
    {} as GeolocationContextProvider
)
export default GeolocationContext

export const GeolocationProvider = ({ children }) => {
    const { data, name } = useContext(DomainContext)

    // initializing
    const [isGmLoaded, setGmLoaded] = useState(false)

    // viewport data
    const [gBounds, setGBounds] = useState<google.maps.LatLngBounds>()
    const [viewport, setViewport] =
        useState<google.maps.places.PlaceGeometry['viewport']>()
    const [center, setCenter] = useState<google.maps.LatLngLiteral>()
    const [zoom, setZoom] = useState<number>()
    const [cardinals, setCardinals] = useState<Cardinals>()
    const [outerCardinals, setOuterCardinals] = useState<Cardinals>()
    const [geocode, setGeocode] = useState<google.maps.LatLngBounds>()

    const handleViewport = useCallback((viewport) => {
        if (!viewport?.getCenter) return
        const center = {
            lat: viewport.getCenter().lat(),
            lng: viewport.getCenter().lng(),
        }
        setGBounds(null)
        setViewport(viewport)
        setCenter(center)
    }, [])

    const handleBounds: GeolocationContextProvider['setBounds'] = useCallback(
        ({ gBounds, center, zoom }) => {
            // if nothing really changed
            const newCardinals: Cardinals = {
                n: gBounds.getNorthEast().lat(),
                e: gBounds.getNorthEast().lng(),
                s: gBounds.getSouthWest().lat(),
                w: gBounds.getSouthWest().lng(),
            }

            // test if this is not a "flat" map
            if (
                newCardinals.n === newCardinals.s ||
                newCardinals.e === newCardinals.w
            )
                return

            // if cardinals are still the same
            if (
                cardinals &&
                cardinals.n === newCardinals.n &&
                cardinals.s === newCardinals.s &&
                cardinals.e === newCardinals.e &&
                cardinals.w === newCardinals.w
            )
                return

            // furtest west, north, south, east we seen the map
            const newOuterCardinals: Cardinals = {
                n:
                    (outerCardinals &&
                        Math.max(outerCardinals.n, newCardinals.n)) ||
                    newCardinals.n,
                e:
                    (outerCardinals &&
                        Math.max(outerCardinals.e, newCardinals.e)) ||
                    newCardinals.e,
                s:
                    (outerCardinals &&
                        Math.min(outerCardinals.s, newCardinals.s)) ||
                    newCardinals.s,

                w:
                    (outerCardinals &&
                        Math.min(outerCardinals.w, newCardinals.w)) ||
                    newCardinals.w,
            }

            setViewport(undefined)
            setCenter(center)
            setZoom(zoom)
            setGBounds(gBounds)
            setCardinals(newCardinals)
            setOuterCardinals(newOuterCardinals)
        },
        [cardinals, outerCardinals]
    )

    // if all fails show the netherlands
    const setDefault = useCallback(() => {
        const bounds = new window.google.maps.LatLngBounds()
        bounds.extend(
            new window.google.maps.LatLng(50.7503837, 3.3315999999999804)
        ) // sw
        bounds.extend(new window.google.maps.LatLng(53.6756, 7.227140500000019)) // ne
        handleViewport(bounds)
        setGeocode(bounds)
    }, [handleViewport])

    // set the map up to the correct viewport
    useEffect(() => {
        if (!isGmLoaded) return
        if (viewport) return
        if (geocode) return

        const bounds = new window.google.maps.LatLngBounds()

        // first use most specific
        if (data?.viewport) {
            if (data.viewport?.type === 'Polygon') {
                data.viewport.coordinates.forEach((group) => {
                    group.forEach(([lng, lat]) => {
                        bounds.extend(new window.google.maps.LatLng(lat, lng))
                    })
                })
                handleViewport(bounds)
                setGeocode(bounds)
                return
            }
        }

        // then use geocode or name
        if (data?.geocode || name) {
            geocoder(data?.geocode || name)
                .then((result) => {
                    handleViewport(result.geometry.viewport)
                    setGeocode(result.geometry.viewport)
                })
                .catch(() => {
                    setDefault()
                })
        }

        setDefault()
    }, [
        data?.geocode,
        data?.viewport,
        gBounds,
        geocode,
        handleViewport,
        isGmLoaded,
        name,
        setDefault,
        viewport,
    ])

    const value = useMemo(
        () => ({
            gBounds,
            viewport,
            center,
            zoom,
            cardinals,
            outerCardinals,

            isGmLoaded,
            setGmLoaded,

            setBounds: handleBounds,
            setViewport: handleViewport,

            zoomOut: () => setZoom((zoom) => (zoom && zoom - 1) || 11),
            zoomIn: () => setZoom((zoom) => (zoom && zoom + 1) || 13),
        }),
        [
            gBounds,
            viewport,
            center,
            zoom,
            cardinals,
            outerCardinals,
            isGmLoaded,
            handleBounds,
            handleViewport,
        ]
    )

    return (
        <GeolocationContext.Provider value={value}>
            {children}
        </GeolocationContext.Provider>
    )
}
