import { t } from '@zupr/i18n'
import { createBoundingBox, geocoder } from '@zupr/utils/geolocation'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import { getStorageJson, setStorageJson } from '../utils/storage'
import GeolocationContext from './geolocation'

interface ShippingDetails {
    zipcode?: string
    number?: string
    name?: string
    company?: string
    address?: string
    city?: string
    deliverInstructions?: string
    numberAddition?: string
    invoiceName?: string
    invoiceCompany?: string
    invoiceZipcode?: string
    invoiceAddress?: string
    invoiceCity?: string
    invoiceNumber?: string
    invoiceNumberAddition?: string
    phone?: string
    email?: string
    emailVerify?: string
    newsletter?: string
    terms?: boolean
    vatReceipt?: boolean
    invoice?: boolean
}

interface ShopperPosition extends google.maps.LatLngLiteral {
    watch?: boolean
}

interface ShopperContextProvider {
    shippingDetails: ShippingDetails
    position?: ShopperPosition
    place?: google.maps.places.PlaceResult
    distance?: number
    showShopperLocationForm: boolean
    updateShippingDetails: (values: Partial<ShippingDetails>) => void
    setPosition: React.Dispatch<React.SetStateAction<ShopperPosition>>
    setDistance: React.Dispatch<React.SetStateAction<number>>
    setPlace: React.Dispatch<
        React.SetStateAction<google.maps.places.PlaceResult>
    >
    toggleShopperLocationForm: (open?: boolean) => void
}

interface ShopperLocation {
    position?: ShopperPosition
    place?: google.maps.places.PlaceResult
    distance?: number
    declined: boolean
    locating: boolean
    unavailable: boolean
    box: string | null
    yourLocation: string
    getPosition: () => void
    setPlace: (place: google.maps.places.PlaceResult) => void
    setDistance: React.Dispatch<React.SetStateAction<number>>
    deleteLocation: () => void
}

export const ShopperContext = React.createContext<ShopperContextProvider>(
    {} as ShopperContextProvider
)
export default ShopperContext

export const ShopperProvider = ({ children }) => {
    const [showShopperLocationForm, setShopperLocationOpen] = useState(false)

    const [shippingDetails, setShippingDetails] = useState<ShippingDetails>(
        getStorageJson('shipping-details') || {}
    )

    // user position
    const [place, setPlace] = useState<google.maps.places.PlaceResult>(
        getStorageJson('place')
    )
    const [position, setPosition] = useState<ShopperPosition>()
    const [distance, setDistance] = useState<number>(getStorageJson('distance'))

    const updateShippingDetails: ShopperContextProvider['updateShippingDetails'] =
        useCallback((values) => {
            setShippingDetails((shippingDetails) => {
                const merged = { ...shippingDetails, ...values }
                setStorageJson('shipping-details', merged)
                return merged
            })
        }, [])

    // sync place with session storage
    useEffect(() => {
        if (!place) {
            window.localStorage.removeItem('place')
            return
        }
        setStorageJson('place', place)
    }, [place])

    // sync position with session storage
    useEffect(() => {
        if (!position) return
        setStorageJson('position', position)
    }, [position])

    // sync position with session storage
    useEffect(() => {
        if (!distance) {
            window.localStorage.removeItem('distance')
            return
        }
        setStorageJson('distance', distance)
    }, [distance])

    const toggleShopperLocationForm = useCallback((open?: boolean) => {
        if (open !== undefined) return setShopperLocationOpen(open)
        setShopperLocationOpen((open) => !open)
    }, [])

    return (
        <ShopperContext.Provider
            value={{
                shippingDetails,
                position,
                place,
                distance,
                showShopperLocationForm,
                updateShippingDetails,
                setPosition,
                setPlace,
                setDistance,
                toggleShopperLocationForm,
            }}
        >
            {children}
        </ShopperContext.Provider>
    )
}

export const useShopper = () => {
    const shopper = useContext(ShopperContext)
    return shopper
}

export const useShippingDetails = () => {
    const { shippingDetails } = useContext(ShopperContext)
    return shippingDetails
}

export const useUpdateShippingDetails = () => {
    const { updateShippingDetails } = useContext(ShopperContext)
    return updateShippingDetails
}

export const useShopperLocation = (): ShopperLocation => {
    const { setViewport } = useContext(GeolocationContext)
    const {
        position,
        place,
        distance,
        shippingDetails,
        setPosition,
        setPlace,
        setDistance,
        updateShippingDetails,
    } = useShopper()

    const [unavailable, setUnavailable] = useState<boolean>()
    const [declined, setDeclined] = useState<boolean>()
    const [locating, setLocating] = useState(false)

    const watchPosition = useRef<ReturnType<Geolocation['watchPosition']>>()

    useEffect(() => {
        const watcher = watchPosition.current
        return () => {
            if (watcher) navigator.geolocation.clearWatch(watcher)
        }
    }, [])

    const handlePositionFromPlace = useCallback(
        (place: google.maps.places.PlaceResult) => {
            if (place?.geometry) {
                setPlace(place)
                setPosition({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                })
                setViewport(place.geometry.viewport)
            }
        },
        [setPlace, setPosition, setViewport]
    )

    const handlePlace: ShopperLocation['setPlace'] = useCallback(
        (place) => {
            handlePositionFromPlace(place)

            // update zipcode gotten from place
            const zipcode = place.address_components.find(
                (component) => component.types[0] === 'postal_code'
            )
            const street_number = place.address_components.find(
                (component) => component.types[0] === 'street_number'
            )

            if (zipcode && street_number) {
                updateShippingDetails({
                    zipcode: zipcode?.long_name,
                    number: street_number?.long_name,
                })
            }

            if (zipcode && !street_number) {
                updateShippingDetails({
                    zipcode: zipcode?.long_name,
                    number: null,
                })
            }
        },
        [handlePositionFromPlace, updateShippingDetails]
    )

    // We got a geocode fix. find a place near this geocode
    const handleGeolocation = useCallback(
        async (location: google.maps.LatLngLiteral) => {
            const result = await geocoder(`${location.lat},${location.lng}`)
            handlePlace(result)
        },
        [handlePlace]
    )

    const startWatchingPosition = useCallback(
        (position) => {
            // set up watcher
            if (watchPosition.current) {
                return
            }

            try {
                watchPosition.current = navigator.geolocation.watchPosition(
                    ({ coords }) => {
                        setPosition({
                            lat: coords.latitude,
                            lng: coords.longitude,
                            watch: true,
                        })
                    },
                    console.error,
                    {
                        enableHighAccuracy: false,
                    }
                )
            } catch (e) {
                setPosition({
                    ...position,
                    watch: false,
                })
            }
        },
        [setPosition]
    )

    const getPosition = useCallback(() => {
        // stop if there is no navigator
        if (!navigator?.geolocation?.getCurrentPosition) {
            return null
        }

        setLocating(true)

        navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
                // found position
                setLocating(false)
                setPosition({
                    lat: coords.latitude,
                    lng: coords.longitude,
                    watch: true,
                })

                // find place
                handleGeolocation({
                    lat: coords.latitude,
                    lng: coords.longitude,
                })

                startWatchingPosition({
                    lat: coords.latitude,
                    lng: coords.longitude,
                })
            },
            ({ code, POSITION_UNAVAILABLE, PERMISSION_DENIED }) => {
                if (code === POSITION_UNAVAILABLE) {
                    setUnavailable(true)
                }
                if (code === PERMISSION_DENIED) {
                    setDeclined(true)
                }
                setLocating(false)
                setPosition({
                    ...position,
                    watch: false,
                })
            }
        )
    }, [handleGeolocation, position, setPosition, startWatchingPosition])

    // use store position to reset
    useEffect(() => {
        const stored = getStorageJson('position')
        if (!stored) return
        setPosition(stored)
        if (stored.watch) {
            startWatchingPosition(stored)
        }
    }, [setPosition, startWatchingPosition])

    const deleteLocation = useCallback(() => {
        setPosition(null)
        setPlace(null)
        setDistance(null)
    }, [setDistance, setPlace, setPosition])

    const box = useMemo(() => {
        if (!position) return null
        if (!distance) return null
        return createBoundingBox(position, distance)
    }, [distance, position])

    const yourLocation = useMemo(() => {
        if (!position) return t('Your location')
        const { zipcode, number, numberAddition } = shippingDetails
        if (!zipcode && place) return place.formatted_address
        if (!zipcode) return t('Your location')

        return `${zipcode}${!!number ? `, ${number}` : ''}${
            !!numberAddition ? `-${numberAddition}` : ''
        }`
    }, [position, place, shippingDetails])

    return {
        box,
        position,
        place,
        distance,
        declined,
        locating,
        unavailable,
        yourLocation,
        setDistance,
        getPosition,
        setPlace: handlePlace,
        deleteLocation,
    }
}
