import { DomainConfig } from '@zupr/types/fo'
import React, { useEffect } from 'react'

import '../../scss/react/frontoffice/area.scss'

interface DomainProviderProps {
    children: React.ReactNode
    config: DomainConfig
}

interface DomainContextProvider extends DomainConfig {
    shoppingAreaSlug: DomainConfig['slug']
}

export const DomainContext = React.createContext<DomainContextProvider>(
    {} as DomainContextProvider
)

export const useDomain = () => React.useContext(DomainContext)

export default DomainContext
export const DomainProvider = ({ children, config }: DomainProviderProps) => {
    // determine the slug and fallback to 'nederland' if none is found after fetching shoppingArea
    const shoppingAreaSlug = config?.slug || 'nederland'

    useEffect(() => {
        if (!document) return
        if (!shoppingAreaSlug) return
        document.body.className = `theme-area-${shoppingAreaSlug}`
        return () => {
            document.body.className = ''
        }
    }, [shoppingAreaSlug])

    return (
        <DomainContext.Provider
            value={{
                shoppingAreaSlug,
                ...config,
            }}
        >
            {children}
        </DomainContext.Provider>
    )
}
