import defaults from './defaults.json'
import types from './types.json'
import material from './material.json'
import sort from './sort.json'
import errors from './errors.json'

const enUs = {
    ...defaults,
    ...material,
    sort,
    types,
    errors,
}

export default enUs
