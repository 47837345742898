import React from 'react'
import { ReactComponent as CloseIcon } from '../../../svg/close.svg'

import '../../../scss/react/elements/close.scss'

const CloseButton = ({ onClick }) => (
    <button className="btn-close" onClick={onClick}>
        <CloseIcon />
    </button>
)

export default CloseButton
