import { useEffect, useState } from 'react'

const useModal = (open?: Boolean) => {
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        const scrollY = document.documentElement.scrollTop

        if (open) {
            document.body.classList.add('ReactModal__Body--open')
        }

        // only when we have overflow add scrollbar
        if (open && !opened) {
            if (document.body.clientHeight >= document.body.scrollHeight) return
            document.body.classList.add('ReactModal__Body--fixed')
            document.body.style.top = `-${scrollY}px`
            setOpened(true)
        }

        if (!open) {
            document.body.classList.remove('ReactModal__Body--open')
        }

        // only when we had overflow remove scrollbar
        if (!open && opened) {
            const scrollY = document.body.style.top
            document.body.classList.remove('ReactModal__Body--fixed')
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
            document.body.style.top = null
            setOpened(false)
        }
    }, [open, opened])
}

export default useModal
