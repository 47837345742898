import Polyglot from 'node-polyglot'
import { flatten } from 'flat'

import nl_NL from './nl_NL'
import en_US from './en_US'

let language = 'nl'

const languages = {
    nl: nl_NL,
    en: en_US,
}

// nederlands is default language
const polyglot = new Polyglot({
    phrases: languages[language],
    locale: language,
})

export const t = (phrase, ...args) => {
    // find values and context
    const values = args.find((arg) => typeof arg === 'object') || {}
    const context = args.find((arg) => typeof arg === 'string')

    if (!phrase) return null

    if (typeof phrase !== 'string') {
        console.warn('not a string', phrase)
        return JSON.stringify(phrase)
    }

    // if there is an translation in context
    if (context && polyglot.has(`${context}.${phrase}`)) {
        phrase = `${context}.${phrase}`
    }

    if (!polyglot.has(phrase)) {
        polyglot.extend({ [phrase]: phrase })
    }

    // make sure smartcount is set
    if (phrase.includes('||||')) {
        values.smart_count =
            values.smart_count !== undefined ? values.smart_count : '∞'
    }

    return polyglot.t(phrase, flatten(values))
}

export const getContext = (context) => languages[language][context]

export const hasPhrase = (phrase, context) =>
    (context && polyglot.has(`${context}.${phrase}`)) || polyglot.has(phrase)

export const setLanguage = (newLanguage) => {
    language = newLanguage
    polyglot.clear()
    polyglot.locale(language)
    polyglot.extend(languages[language] || languages[language.split('_')[0]])
}
