import { getDevice } from '@zupr/utils/device'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import NotificationSystem from 'react-notification-system'

import { useQuery } from './route'

import '../../scss/react/components/notification.scss'

interface Notification {
    uid?: string
    title?: string
    message?: string
    level: 'info' | 'warning' | 'success' | 'error'
    autoDismiss?: number
    dismissible?: boolean
}

interface UxContextProvider {
    userAgent: string
    search: string
    setSearch: React.Dispatch<React.SetStateAction<string>>
    sidebarSmall: boolean
    setSidebarSmall: React.Dispatch<React.SetStateAction<boolean>>
    addNotification: (notification: Notification) => Notification
    removeNotification: (notification: Notification) => void
    editNotification: (notification: Notification) => void
}

interface NotificationSystemProvider {
    addNotification: (notification: Notification) => Notification
    removeNotification: (notification: Notification) => void
    editNotification: (
        uid: Notification['uid'],
        notification: Notification
    ) => void
}

export const UxContext = React.createContext<UxContextProvider>(
    {} as UxContextProvider
)
export default UxContext

export const useMediaQuery = (query: string, initial: boolean) => {
    const [matches, setMatches] = useState(initial)

    useEffect(() => {
        const mediaQuery = window.matchMedia(query)

        setMatches(mediaQuery.matches)

        const handler = (event) => setMatches(event.matches)

        mediaQuery.addListener(handler)

        return () => mediaQuery.removeListener(handler)
    }, [query])

    return matches
}

export const useIsMobile = () => {
    const { userAgent } = React.useContext(UxContext)
    const device = getDevice(userAgent)
    return useMediaQuery(`(max-width: 768px)`, device.type === 'mobile')
}

export const useIsSmall = () => {
    const { userAgent } = React.useContext(UxContext)
    const device = getDevice(userAgent)
    return useMediaQuery(
        `(max-width: 1024px)`,
        device.type === 'mobile' || device.type === 'tablet'
    )
}

export const useUx = () => React.useContext(UxContext)

export const UxProvider = ({ children, userAgent }) => {
    const query = useQuery()

    const [sidebarSmall, setSidebarSmall] = useState(false)
    const [search, setSearch] = useState(`${query?.search || ''}`)

    const notificationSystem = useRef<NotificationSystemProvider>()

    const addNotification = useCallback((notification) => {
        if (!notificationSystem.current) return
        return notificationSystem.current.addNotification(notification)
    }, [])

    const removeNotification = useCallback((notification) => {
        if (!notificationSystem.current) return
        return notificationSystem.current.removeNotification(notification)
    }, [])

    const editNotification = useCallback((notification) => {
        if (!notification) return
        if (!notificationSystem.current) return
        return notificationSystem.current.editNotification(
            notification.uid,
            notification
        )
    }, [])

    return (
        <UxContext.Provider
            value={{
                userAgent,
                search,
                setSearch,
                sidebarSmall,
                setSidebarSmall,
                addNotification,
                removeNotification,
                editNotification,
            }}
        >
            {children}
            <NotificationSystem ref={notificationSystem} />
        </UxContext.Provider>
    )
}
