import { t } from '@zupr/i18n'
import React from 'react'
import ReactMarkdown from 'react-markdown'

export interface Dutch {
    /**
     * @deprecated translate it in english please
     */
    dutch?: boolean
}
export type TransValues = { [key: string]: string | number }

interface Props extends Dutch {
    label: string
    className?: string
    plural?: boolean
    html?: boolean
    markdown?: boolean
    context?: string
    values?: TransValues
    linkTarget?: '_blank' | '_self'
}

const Trans = ({
    html,
    className,
    plural,
    context,
    markdown,
    label,
    linkTarget,
    ...props
}: Props) => {
    const values = { ...(props.values || {}) }

    if (typeof plural === 'boolean') {
        values.smart_count = plural ? 9999 : 1
    }

    const translated = context ? t(label, context, values) : t(label, values)

    if (markdown) {
        return (
            <ReactMarkdown linkTarget={linkTarget}>{translated}</ReactMarkdown>
        )
    }

    if (html) return <span dangerouslySetInnerHTML={{ __html: translated }} />
    if (className) return <span className={className}>{translated}</span>

    return <React.Fragment>{translated}</React.Fragment>
}

export default Trans
