let storage: Storage
let session: Storage

if (typeof window === 'undefined') {
    storage = {
        length: 0,
        getItem: () => null,
        setItem: () => null,
        clear: () => null,
        removeItem: () => null,
        key: () => null,
    }
    session = {
        length: 0,
        getItem: () => null,
        setItem: () => null,
        clear: () => null,
        removeItem: () => null,
        key: () => null,
    }
} else {
    storage = window.localStorage
    session = window.sessionStorage
}

const getStorageJson = (key: string) => {
    try {
        return storage.getItem(key) && JSON.parse(storage.getItem(key))
    } catch (err) {
        return null
    }
}

const setStorageJson = (key: string, data: any) =>
    storage.setItem(key, JSON.stringify(data))

const getSessionJson = (key: string) => {
    try {
        return session.getItem(key) && JSON.parse(session.getItem(key))
    } catch (err) {
        return null
    }
}
const setSessionJson = (key: string, data: any) =>
    session.setItem(key, JSON.stringify(data))

export {
    session,
    storage,
    setSessionJson,
    getSessionJson,
    getStorageJson,
    setStorageJson,
}
export default storage
