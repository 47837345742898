import React from 'react'

import ErrorBoundary from '../error/boundary'

import '../../../scss/react/components/loading.scss'

interface LoadingProps {
    children?: React.ReactNode
}

export const Loading = ({ children }: LoadingProps) => (
    <div className="is-loading">
        <h2>
            <span className="loading" />
            {children}
        </h2>
    </div>
)

interface SuspenseProps {
    children?: React.ReactNode
    fallback?: React.ReactNode
}

const Suspense = ({ children, fallback }: SuspenseProps) => (
    <React.Suspense fallback={fallback || <Loading />}>
        <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
)

export default Suspense
