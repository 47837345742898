import React from 'react'
import * as Sentry from '@sentry/nextjs'

import '../../../scss/react/components/error-boundary.scss'

export default class ErrorBoundary extends React.Component {
    state = {
        error: null,
        hasError: false,
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error }
    }

    componentDidCatch(error, extra) {
        console.error(error, extra)
        Sentry.captureException(error, { extra })
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    {`Something unexpected happened. The Zupr team has been notified of the error.`}
                </div>
            )
        }
        return <React.Fragment>{this.props.children}</React.Fragment>
    }
}
