import Cookies from 'js-cookie'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

import { getStorageJson, setStorageJson } from '../utils/storage'
import DomainContext from './domain'

export interface Consent {
    analytics: boolean
    tracking: boolean
}

interface TrackingContextProvider {
    consent: Consent
    setConsent: (consent: Consent) => void
}

export const TrackingContext = React.createContext(
    {} as TrackingContextProvider
)
export default TrackingContext

const analyticsCookiePrefix = 'zupr-stroopwafel'

export const TrackingProvider = ({ children }) => {
    const { analytics, googleAnalyticsId, googleTagManager } =
        useContext(DomainContext)
    const consent = useMemo(() => getStorageJson('cookie-consent'), [])

    // save consent setting to storage
    const handleConsent = useCallback(async (consent: Consent) => {
        setStorageJson('cookie-consent', consent)

        const cookies = Object.keys(Cookies.get())

        // // delete cookies
        if (cookies.length && (!consent.analytics || !consent.tracking)) {
            cookies.forEach((cookie) => {
                // is this an analytics
                const isAnalytic =
                    cookie.startsWith(analyticsCookiePrefix) ||
                    cookie.startsWith('_ga')
                const remove = isAnalytic
                    ? !consent.analytics
                    : !consent.tracking
                // delete tracking cookies
                if (remove) {
                    Cookies.remove(cookie, {
                        path: '/',
                        domain: `.${window.location.hostname}`,
                    })
                }
            })
        }

        // restart with new consent
        window.location.reload()
    }, [])

    // turn on analytics
    useEffect(() => {
        if (!consent) return

        const trackingIds: string[] = []

        if (analytics) trackingIds.push(...analytics)
        if (googleAnalyticsId) trackingIds.push(googleAnalyticsId)

        // cookie
        const storage = consent?.analytics ? null : 'none'
        const storeGac = !!consent?.tracking
        const anonymizeIp = !consent?.tracking

        const trackers = [
            {
                trackingId: 'G-D2BKD30K73',
                gaOptions: {
                    name: null,
                    storage,
                    storeGac,
                    cookieName: analyticsCookiePrefix,
                    debug_mode: true,
                },
                gtagOptions: {
                    debug_mode: true,
                },
            },
        ]

        trackingIds.forEach((trackingId, i) =>
            trackers.push({
                trackingId,
                gaOptions: {
                    name: `extraTracker${i + 1}`,
                    cookieName: `${analyticsCookiePrefix}-${i + 1}`,
                    storage,
                    storeGac,
                    debug_mode: true,
                },
                gtagOptions: {
                    debug_mode: true,
                },
            })
        )

        ReactGA.initialize(trackers)

        // no tracking consent anonymize the ip
        if (anonymizeIp) {
            ReactGA.set({ anonymizeIp })
        }

        ReactGA.set({ debug_mode: true })

        // set up two dimensions. true === anonymous, false === cookie
        ReactGA.set({ dimension1: consent.analytics.toString() })
    }, [analytics, consent, googleAnalyticsId])

    // turn on GTM
    useEffect(() => {
        if (!googleTagManager) return
        if (!consent) return
        if (!consent.tracking) return

        TagManager.initialize({
            gtmId: googleTagManager,
        })
    }, [consent, googleTagManager])

    return (
        <TrackingContext.Provider
            value={{ consent, setConsent: handleConsent }}
        >
            {children}
        </TrackingContext.Provider>
    )
}
